import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'
import jwt from 'jsonwebtoken'
import router from '@/router'

import FormData from 'form-data'


  var axios = Axios.create({
       baseURL: process.env.VUE_APP_BASE_URL
  })

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      snackbar:{
        content: '',
        color: '',
      },
    markets: [],
    authStatus: '',
    sendStatus:'',
    token: localStorage.getItem('token') || '',
    isMobile: false,
    deviceSize: 0,
    permissions: localStorage.getItem('permission'),
    showOrderDialog: false,
    showOrderDetailsDialog: false,
    showEditFreelancerDialog: false,
    showEditUserDialog: false,
    showTemplateCardDialog: false,
    showEditTemplateDialog: false,
    showNewTemplateDialog: false,
    showNewFreelancerDialog: false,
    showEditOrderDialog: false,
    showEditInvoiceDialog: false
  },
  getters: {
    authorized: state => !!state.token,
    authstatus: state => state.authStatus,
    isMobile: state => state.deviceSize < 768
  },
  mutations: {
    showMessage (state, payload) {
        state.snackbar.content = payload.content
        state.snackbar.color = payload.color
    },
    getMarkets (state,payload){
        state.markets = payload.markets
    },
    auth_request (state) {
     state.authStatus = 'loading'
    },
    sending (state) {
        state.sendStatus = 'sending'
       },
    getting (state) {
        state.sendStatus = 'getting'
     },
    auth_success (state, payload) {
      state.authStatus = 'success'
      state.token = payload.token
      console.log(payload.permission)
      state.permissions = payload.permission
      console.log(state.permissions)
    },
    auth_error (state) {
      state.authStatus = 'error'
    },
    resize (state, deviceSize){
      state.deviceSize = deviceSize;
    },
    logout (state) {
      state.authStatus = ''
      state.token = ''
    }
  },
  actions: {
    login ({ commit }, userData) {
        return new Promise((resolve, reject) => {
          commit('auth_request')
          axios.post('/login/user', { username: userData.username, password: userData.password })
            .then(response => {
              const token = response.data.access_token
              const refreshToken = response.data.refresh_token;
              const decoded = jwt.decode(token);
              const name = decoded.name
              const permission = decoded.permissions
              const id = decoded.id
              console.log(decoded)
              localStorage.setItem('permission', permission)
              // storing jwt in localStorage. https cookie is safer place to store
              localStorage.setItem('name', name)
              localStorage.setItem('token', token)
              localStorage.setItem('refreshToken', refreshToken)
              localStorage.setItem('id', id)

              axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
              // mutation to change state properties to the values passed along
             var payload = { token: token, permission: permission}
              commit('auth_success', payload)
              resolve(response)
            })
            .catch(err => {
              console.log(err)

              commit('auth_error')
              localStorage.removeItem('token')
              reject(err)
            })
        })
    }, 
    loginFreelancer ({ commit }, userData) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios.post('/login/freelancer', { username: userData.username, password: userData.password })
          .then(response => {
            const token = response.data.access_token
            const refreshToken = response.data.refresh_token;
            const decoded = jwt.decode(token);
            const name = decoded.name
            const permission = decoded.permissions
            const id = decoded.id

            localStorage.setItem('permission', permission)
            console.log(permission)
            // storing jwt in localStorage. https cookie is safer place to store
            localStorage.setItem('name', name)
            localStorage.setItem('token', token)
            localStorage.setItem('refreshToken', refreshToken)
            localStorage.setItem('id', id)

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
            // mutation to change state properties to the values passed along
           
            commit('auth_success', {token})
            resolve(response)
          })
          .catch(err => {
            console.log(err)

            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
  }, 
  forgotPassword ({commit}, userData) {
    return new Promise((resolve, reject) => {
      axios.patch('/forgotPassword/'+userData.userType,{email: userData.email}, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
            console.log(err)
            reject(err)
          })
      })
    },
  resetPassword ({commit}, userData) {
    return new Promise((resolve, reject) => {
      axios.patch('/auth/password/reset?token='+userData.token,{password: userData.password}, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
            console.log(err)
            reject(err)
          })
      })
    },
    updatePassword ({commit}, userData) {
      return new Promise((resolve, reject) => {
        axios.patch('/auth/password/update',{password: userData.password,current_password: userData.current_password}, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            if(err.response.status == 401)
              this.dispatch('refreshToken')
              console.log(err)
              reject(err)
            })
        })
      },
    getFreelancers () {
      return new Promise((resolve, reject) => {
        axios.get('/freelancers', {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            if(err.response.status == 401)
             this.dispatch('refreshToken')
            console.log(err)
            reject(err)
          })
      })
  },
  getUsers () {
    return new Promise((resolve, reject) => {
      axios.get('/users', {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
},
getUser ({commit},userData) {
  return new Promise((resolve, reject) => {
    axios.get('/users/'+userData.userId, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')
        console.log(err)
        reject(err)
      })
  })
},
  getFreelancerById ({commit}, userData) {
    return new Promise((resolve, reject) => {
      axios.get('/freelancers/'+userData.freelancerId, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
},
  getFreelancersByMarket ({commit}, userData) {
    return new Promise((resolve, reject) => {
      axios.get('/freelancers/market/'+userData.marketId, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
},
generateInvoiceByFreelancerId ({commit},userData) {
  return new Promise((resolve, reject) => {
    axios.post('/invoice/generate/'+userData.freelancerId, {year: userData.year, month: userData.month} , {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')
        console.log(err)
        reject(err)
      })
  })
},
deleteInvoiceById ({commit},userData) {
  return new Promise((resolve, reject) => {
    axios.delete('/invoice/'+userData.invoiceId, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')
        console.log(err)
        reject(err)
      })
  })
},
getInvoicesByFreelancerId ({commit},userData) {
  return new Promise((resolve, reject) => {
    axios.get('/invoice/?freelancerId='+userData.freelancerId+'&year='+userData.year+'&month='+userData.month, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')
        console.log(err)
        reject(err)
      })
  })
},
editInvoice ({commit},userData) {
  return new Promise((resolve, reject) => {
    axios.patch('/invoice', {invoice: userData.invoice}, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')
        console.log(err)
        reject(err)
      })
  })
},
saveContent ({commit},userData) {
  return new Promise((resolve, reject) => {
    axios.post('/orders/content/update', {orderId: userData.orderId ,content: userData.content}, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')
        console.log(err)
        reject(err)
      })
  })
},

  sendEmailToAccounts({commit},userData){
    return new Promise((resolve, reject) => {
      axios.post('/invoice/emailAccounts/'+userData.invoiceId, {}, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
  },
    getOrders ({commit}, userData) {
      return new Promise((resolve, reject) => {
        axios.get('/orders?'+userData.query, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            if(err.response.status == 401)
              this.dispatch('refreshToken')
            console.log(err)
            reject(err)
          })
      })
  },
  getOrdersByStatus ({commit}, userData) {
    return new Promise((resolve, reject) => {
      axios.get('/orders/status?'+userData.query, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
},
  getOrderStatuses () {
    return new Promise((resolve, reject) => {
      axios.get('/orders/statuses', {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
},

  editOrder ({commit},userData) {
    return new Promise((resolve, reject) => {
      axios.patch('/orders', {order: userData.order, templateElements: userData.templateElements}, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
  },
  deleteOrder ({commit},userData) {
    return new Promise((resolve, reject) => {
      axios.delete('/orders/'+userData.orderId, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
  },
  getOrderWithId ({commit},userData) {
    return new Promise((resolve, reject) => {
      axios.get('/orders/'+userData.orderId, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
},
assignOrder ({commit},userData) {
  return new Promise((resolve, reject) => {
    axios.patch('/orders/assign/'+userData.orderId, {freelancerId: userData.freelancerId} ,{headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')
        console.log(err)
        reject(err)
      })
  })
},
unassignOrder ({commit},userData) {
  return new Promise((resolve, reject) => {
    axios.patch('/orders/unassign/'+userData.orderId, {} ,{headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')
        console.log(err)
        reject(err)
      })
  })
},
approveOrder ({commit},userData) {
  return new Promise((resolve, reject) => {
    axios.patch('/orders/approve/'+userData.orderId,{},{headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')
        console.log(err)
        reject(err)
      })
  })
},
    getWebsites () {
      return new Promise((resolve, reject) => {
        axios.get('/websites/', {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            if(err.response.status == 401)
              this.dispatch('refreshToken')
            console.log(err)
            reject(err)
          })
      })
  },
  getCompanies () {
    return new Promise((resolve, reject) => {
      axios.get('/companies/', {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
},
  getOrderTypes () {
    return new Promise((resolve, reject) => {
      axios.get('/orders/type', {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
  },
  getMarkets () {
    return new Promise((resolve, reject) => {
        if(this.state.markets.length > 0){
          resolve (this.state.markets)
        }
        else {
          console.log(this.state.markets)
            axios.get('/markets', {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
            .then(response => {
              this.state.markets = response.data.data
              resolve(response.data.data)
            })
            .catch(err => {
              if(err.response.status == 401)
                this.dispatch('refreshToken')
              console.log(err)
              reject(err)
            })
        }    
 
    })
  },
  getTerms(){
    return new Promise((resolve, reject) => {
      axios.get('/terms', {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
  },
  acceptTerms({ commit }, userData){
    return new Promise((resolve, reject) => {
      axios.get('/freelancers/activate/'+userData.inviteCode, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
  },
  getTemplates () {
    return new Promise((resolve, reject) => {
      axios.get('/templates', {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
  },
  getTemplatesByMarket ({commit},userData) {
    return new Promise((resolve, reject) => {
      axios.get('/templates/markets/'+userData.marketId, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
  },
  getTemplateDetails ({commit} , userData) {
    return new Promise((resolve, reject) => {
      axios.get('/templates/'+userData.templateId, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
  },
  getTemplateElements () {
    return new Promise((resolve, reject) => {
      axios.get('/templateElements', {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
  },
  disableTemplateById ({commit}, userData) {
    return new Promise((resolve, reject) => {
      axios.patch('/templates/disable/'+userData.templateId,{}, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
  },
  editTemplateDetails ({commit}, userData) {
    return new Promise((resolve, reject) => {
      axios.patch('/templates/'+userData.template.id,{template: userData.template}, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
  },
  createOrder ({commit} , userData) {
    return new Promise((resolve, reject) => {
      axios.post('/orders', {order: userData.order, templateElements: userData.templateElements}  ,{headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          if(err.response.status == 401)
            this.dispatch('refreshToken')
          console.log(err)
          reject(err)
        })
    })
},
createTemplate ({commit} , userData) {
  return new Promise((resolve, reject) => {
    axios.post('/templates', {template: userData.template}  ,{headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')
        console.log(err)
        reject(err)
      })
  })
},
createUser ({commit} , userData) {
  return new Promise((resolve, reject) => {
    axios.post('/users', {user: userData.user}  ,{headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
         this.dispatch('refreshToken')
        console.log(err)
        reject(err)
      })
  })
},
createFreelancer ({commit} , userData) {
  return new Promise((resolve, reject) => {
    axios.post('/freelancers', {freelancer: userData.freelancer}  ,{headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')
        console.log(err)
        reject(err)
      })
  })
},
editFreelancer ({commit} , userData) {
  return new Promise((resolve, reject) => {
    axios.patch('/freelancers', {freelancer: userData.freelancer}  ,{headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
         this.dispatch('refreshToken')
        console.log(err)
        reject(err)
      })
  })
},
editUser ({commit} , userData) {
  return new Promise((resolve, reject) => {
    axios.patch('/users', {user: userData.user}  ,{headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')
        console.log(err)
        reject(err)
      })
  })
},
orderDecision ({commit} , userData) {
  return new Promise((resolve, reject) => {
    axios.patch('/orders/decision/freelancer/'+userData.decision, {orderId: userData.orderId}  ,{headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')
        console.log(err)
        reject(err)
      })
  })
},
acceptOrderByUser ({commit} , userData) {
  return new Promise((resolve, reject) => {
    axios.patch('/orders/accept/'+userData.orderId, {}  ,{headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')
        console.log(err)
        reject(err)
      })
  })
},
rejectOrderByUser ({commit} , userData) {
  return new Promise((resolve, reject) => {
    axios.patch('/orders/reject/'+userData.orderId, {rejection_note: userData.rejection_note}  ,{headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')
        console.log(err)
        reject(err)
      })
  })
},
uploadFile ({commit} , userData) {
  return new Promise((resolve, reject) => {

    var bodyFormData = new FormData();
    
    bodyFormData.append('file', userData.file);

    axios.patch('/orders/file/'+userData.orderId, bodyFormData ,{headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')

        console.log(err)
        reject(err)
      })
  })
},

getAnalyticsMarket ({commit} , userData) {
  return new Promise((resolve, reject) => {

    axios.get('/analytics/orders/market?'+userData.query,{headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')

        console.log(err)
        reject(err)
      })
  })
},
getAnalyticsWords ({commit} , userData) {
  return new Promise((resolve, reject) => {

    axios.get('/analytics/words?'+userData.query,{headers: {'Authorization' : 'Bearer ' + localStorage.getItem('token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        if(err.response.status == 401)
          this.dispatch('refreshToken')

        console.log(err)
        reject(err)
      })
  })
},
    logout ({ commit }) {
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('name')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('permission')
        localStorage.removeItem('id')

        delete axios.defaults.headers.common['Authorization']
        router.push('/login')
        resolve()
      })
    },
    refreshToken ({ commit }) {
      return axios.get('refresh_token', {headers: {'Authorization': "bearer " + localStorage.getItem('refreshToken')}})
        .then(response => {
          console.log(response.data)
          const token = response.data.access_token
          const decoded = jwt.decode(token);
          const name = decoded.name
          const permission = decoded.permissions
          const id = decoded.id
          localStorage.setItem('permission', permission)
  
          localStorage.setItem('token', token)
          localStorage.setItem('name', name)
          localStorage.setItem('id', id)

          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

        })
        .catch(() => {
          commit('logout')
          localStorage.removeItem('token')
          router.push('/');
        })
    }
  
  }

})



